import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { JsxElement } from "typescript";

interface AuthProviderProps {
  component: JSX.Element
}

export const AuthProvider = ({ component }: AuthProviderProps) => {
  const isAuthorized = window.sessionStorage.getItem("token");

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthorized) {
      navigate("/login", {
        replace: true,
        state: {
          path: location.pathname,
        }
      });
    }
  }, [isAuthorized]);

  return (
    <>
      {isAuthorized ? component : <></>}
    </>
  );
};
