import {Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip,} from 'chart.js';
import {datasets} from "./datasets";
import {RoleModelCard} from "./RoleModelCard";
import Header from "../../common/Header";
import {useNavigate} from "react-router-dom";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const RoleModel = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const survey = urlSearchParams.get('survey');

  return (
    <div>
      <Header title={'롤모델'}/>
      <div
        className="content-center flex justify-center items-center h-20 w-full"
      >
        <button
          className="text-xl font-semibold w-2/5 h-12 rounded-lg text-slate-50 bg-blue-500 shadow-md"
          onClick={() => {
            navigate('/rolemodel/survey')
          }}>
          설문조사
        </button>
      </div>
      {survey === 'success' ?
        <div style={{display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20}}>
          {datasets.map(dataset =>
            <RoleModelCard
              dataset={dataset}/>)
          }
        </div> :
        <div>설문조사를 완료해주세요.</div>
      }
    </div>
  );
}
