import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PreferenceForm } from "./PreferenceForm";
import request from '../../../common/axiosInstance';

// Utils
const dateToString = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const Preference = () => {

  const today = new Date();

  // Hooks
  const navigate = useNavigate();

  // States
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(today);
  const [startTime, setStartTime] = useState<string>("06:00");
  const [difficulty, setDifficulty] = useState<"쉬움"|"보통"|"어려움">("보통");

  // Handlers
  const handleDateChange = (value: string, isStart: boolean) => {
    if (isStart) {
      const newDate = new Date(value);
      setStartDate(newDate);
      if (newDate > endDate)
        setEndDate(newDate);
    } else {
      setEndDate(new Date(value));
    }
  };

  const handleFormSubmit = async (form: PreferenceForm) => {
    const userId = await request.get("/auth/me/id")
      .then((res) => res.data);
    
    request.post("/challengeUsers", {...form, challengeId: 1})
      // .then((res) => console.log(res))
      .then(() => navigate("../set-mission"))
      .catch((err) => console.error(err));
    
  };
  
  return (
    <div className="grow flex flex-col justify-between py-10">
      <div className="space-y-3">
        <h3 className="text-2xl">기간</h3>
        <div style={{display: 'flex', justifyContent: 'space-between',}}>
          <input 
            type="date" 
            className="text-lg"
            min={dateToString(today)}
            value={dateToString(startDate)}
            onChange={(e) => handleDateChange(e.target.value, true)} 
          />~
          <input 
            type="date" 
            className="text-lg"
            min={dateToString(startDate)}
            value={dateToString(endDate)}
            onChange={(e) => handleDateChange(e.target.value, false)}
          />
        </div>
      </div>
      <div className="space-y-3 flex flex-col items-center">
        <h3 className="min-w-full text-2xl">기상 시간</h3>
        <input 
          type="time"
          className="w-1/2 text-lg"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>
      <div className="space-y-3">
        <h3 className="text-2xl">난이도</h3>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {['쉬움 (1개)', '보통 (2개)', '어려움 (3개)'].map((item, idx) => (
            <p
              key={idx}
              onClick={() => setDifficulty(() => {
                switch (idx) {
                  case 0: return "쉬움";
                  case 1: return "보통";
                  default: return "어려움";
                }
              })}
              style={{color: ["쉬움", "보통", "어려움"][idx] === difficulty ? 'black' : 'lightgrey'}}
            >{item}</p>
          ))}
        </div>
      </div>
      <div>
        <button 
          className="w-full h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
          onClick={() => handleFormSubmit({
            startDate: startDate,
            endDate: endDate,
            difficulty: difficulty,
          })}
        >
          미션 계획
        </button>
      </div>
    </div>
  );
};
