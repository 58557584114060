import { useState, useEffect } from 'react';
import Likert7Question from "./Likert7Question";
import Header from '../../common/Header';
import request from '../../common/axiosInstance';
import { useNavigate, useSearchParams } from "react-router-dom";

interface PageContent {
  id: number
  header: string
  questionnaire: string[]
}

const pageContents: PageContent[] = [{
  id: 1,
  header: "당신의 최근 한 달을 기준으로 선택해주세요.",
  questionnaire: [
    "저는 자주 계획을 변경하고 유연하게 대처합니다.",
    "저는 새로운 경험을 선호하며, 예상치 못한 상황을 즐깁니다.",
    "저는 일을 할 때 마감직전에 끝내는 경우가 많습니다.",
    "저는 일을 미리 계획하기보다는 그때그때 처리하는 편입니다.",
    "저는 일상에서 일의 순서와 계획을 중요하게 생각합니다.",
    "저는 목표를 설정하고, 그것을 달성하기 위해 체계적으로 노력합니다.",
    "저는 미리 일정을 정리하고 준비하는 것을 선호합니다.",
    "저는 질서정연하고 조직적인 환경에서 더 효율적으로 일할 수 있습니다.",
  ],
}, {
  id: 2,
  header: "상황: 당신이 휴일에 아무런 계획 없이 하루를 시작할 때를 상상해보세요.",
  questionnaire: [
    "나는 그날의 계획을 미리 정하지 않고, 하루를 즉흥적으로 보내는 것을 선호한다.",
    "나는 미리 계획된 일정보다는 그 순간의 느낌에 따라 행동하는 것이 더 편안하다.",
    "갑작스러운 친구의 초대나 새로운 활동 제안이 있을 때, 일반적으로 즐거운 감정 혹은 긍정적으로 반응한다.",
    "계획하지 않은 새로운 활동을 발견했을 때, 그것을 시도하는 것에 대해 거의 주저하지 않는다.",
    "나는 휴일에도 무엇을 할지 미리 계획하는 것이 더 마음이 편하다.",
    "나는 계획되지 않은 활동보다는 미리 정한 일정을 따르는 것을 선호한다.",
    "갑작스러운 변화나 새로운 제안이 나의 계획을 방해할 때, 종종 불편함을 느낀다.",
    "나는 휴일을 보낼 때도 목표를 정하고 그것에 따라 행동하는 것이 더 만족스럽다.",
  ],
}, {
  id: 3,
  header: "상황: 당신이 중요한 개인과제나 혼자서 진행하는 일을 하는 때를 상상해보세요.",
  questionnaire: [
    "프로젝트나 작업을 수행할 때, 나는 종종 즉흥적으로 문제를 해결하고 새로운 방향을 탐색한다.",
    "미리 세운 계획보다는 그 순간에 느끼는 영감에 따라 일하는 것이 더 효과적이라고 느낀다.",
    "계획이 변경되거나 예상치 못한 상황이 발생해도, 나는 쉽게 적응하고 유연하게 대처한다.",
    "나는 자주 계획 없이 일을 시작하고, 일을 진행하며 무엇을 할지 결정하는 편이다.",
    "중요한 작업을 할 때, 나는 철저한 계획과 조직화된 접근 방식을 선호한다.",
    "나는 일을 시작하기 전에 명확한 목표와 계획을 세우고 그에 따라 행동하는 것이 안정감을 준다고 느낀다.",
    "계획대로 일이 진행되지 않을 때, 나는 불편함과 스트레스를 느낀다.",
    "나는 일정과 마감을 철저하게 관리하며, 계획에 따라 일을 완수하는 것에 큰 만족을 느낀다.",
  ],
}]

function shuffle<T>(array: T[]): T[] {
  array.sort(() => Math.random() - 0.5);
  return array
}

const SurveyPage = ({disableApi}: {disableApi: boolean | undefined}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [formAnswers, setFormAnswers] = useState<(number | undefined)[]>([]);
  const [pageContent, setPageContent] = useState<PageContent | undefined>();

  // Load page contents
  useEffect(() => {
    setFormAnswers([]);
    // Defaults to page 1
    let page = searchParams.get('page');
    if (!page || isNaN(parseInt(page))) {
      navigate({pathname: '/survey', search: '?page=1'});
    } else {
      setCurrentPage(parseInt(page));
    }
    
    // Shuffle questionnaire on component mount
    const content = pageContents[currentPage - 1];
    setPageContent({
      id: content.id,
      header: content.header,
      questionnaire: shuffle(content.questionnaire),
    });
  }, [currentPage]);

  const handleAnswerUpdate = (index: number, value: number) => {
    const newFormAnswers = [...formAnswers];
      newFormAnswers[index] = value;
      setFormAnswers(newFormAnswers);
  };

  const handleFormSubmit = async (answers: (number | undefined)[]) => {
    if (disableApi) {
      navigate('/rolemodel?survey=success');
      return;
    }

    if (answers.length !== 8 || answers.includes(undefined)) {
      alert('Please answer all questions before submitting.');
      return;
    }

    const formAnswers = Array.from(answers.entries())
      .map(([index, value]) => ({
        questionId: ((pageContent?.id || 1) - 1) * 8 + index + 1,
        answer: value?.toString()
      }))

    console.log('Submitted Answers:', formAnswers);

    await request.post(`/forms/${pageContent?.id}/responses`, {
      questionAnswerings: formAnswers,
    })
      .then(response => {
        console.log(response);
        alert('Answers submitted successfully!');
      })
      .then(() => {
        if (currentPage < pageContents.length) {
          window.location.href = `/survey?page=${currentPage + 1}`
        } else {
          window.location.href = "/"
        }
      })
      .catch(error => {
        console.log(error);
        alert('Error submitting formAnswers.');
      });
  };

  return (
    <>
      <Header title={pageContent?.header || ""} />
      <div className="app-body">
        {pageContent?.questionnaire.map((item, idx) => (
          <div key={`a-${idx}`}>
            <Likert7Question
              message={item}
              onAnswerUpdate={(value) => handleAnswerUpdate(idx, value)}
            />
          </div>
        ))}
      </div>
      <div style={{display: 'flex', padding: 16, justifyContent: 'center'}}>
        <button
          className="text-xl font-semibold w-2/5 h-12 rounded-lg text-slate-50 bg-blue-500 shadow-md"
          onClick={() => handleFormSubmit(formAnswers)}
        >
          확인
        </button>
      </div>
    </>
  );
}

export const Survey = ({disableApi}: { disableApi: boolean | undefined }) => {
  // const navigate = useNavigate();

  // const [formAAnswers, setFormAAnswers] = useState<(number | undefined)[]>([]);
  // const [formBAnswers, setFormBAnswers] = useState<(number | undefined)[]>([]);
  // const [formCAnswers, setFormCAnswers] = useState<(number | undefined)[]>([]);
  // const handleAnswerUpdate = (form: string, index: number, value: number) => {
  //   switch (form) {
  //     case 'a':
  //       const newFormAAnswers = [...formAAnswers];
  //       newFormAAnswers[index] = value;
  //       setFormAAnswers(newFormAAnswers);
  //       break;
  //     case 'b':
  //       const newFormBAnswers = [...formBAnswers];
  //       newFormBAnswers[index] = value;
  //       setFormBAnswers(newFormBAnswers);
  //       break;
  //     case 'c':
  //       const newFormCAnswers = [...formCAnswers];
  //       newFormCAnswers[index] = value;
  //       setFormCAnswers(newFormCAnswers);
  //       break;
  //   }
  // };

  // const handleFormSubmit = async (answers: (number | undefined)[]) => {
  //   if (disableApi) {
  //     navigate('/rolemodel?survey=success');
  //     return;
  //   }

  //   if (answers.length !== 8 || answers.includes(undefined)) {
  //     alert('Please answer all questions before submitting.');
  //     return;
  //   }

  //   const formAnswers = Array.from(answers.entries())
  //     .map(([index, value]) => ({
  //       questionId: index,
  //       answer: value?.toString()
  //     }))

  //   console.log('Submitted Answers:', formAnswers);

  //   await request.post('/forms/partA/answers', {
  //     formAnswers: formAnswers
  //   })
  //     .then(response => {
  //       console.log(response);
  //       alert('Answers submitted successfully!');
  //     })
  //     .then(() => {
  //       window.location.href = '/result';
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       alert('Error submitting formAnswers.');
  //     });
  // };

  return (
    <>
      <SurveyPage disableApi={disableApi} />
      {/* <Header title={'설문조사'}/>
      <div className="app-body">
        {shuffle(aQuestionnaires.map((item, idx) => (
          <div key={`a-${idx}`}>
            <Likert7Question
              message={item}
              onAnswerUpdate={(value) => handleAnswerUpdate('a', idx, value)}
            />
          </div>
        )))}

        <Header title={bHeader}/>
        {shuffle(bQuestionnaires.map((item, idx) => (
          <div key={`b-${idx}`}>
            <Likert7Question
              message={item}
              onAnswerUpdate={(value) => handleAnswerUpdate('b', idx, value)}
            />
          </div>
        )))}

        <Header title={cHeader}/>
        {shuffle(cQuestionnaires.map((item, idx) => (
          <div key={`c-${idx}`}>
            <Likert7Question
              message={item}
              onAnswerUpdate={(value) => handleAnswerUpdate('c', idx, value)}
            />
          </div>
        )))}

        <div style={{display: 'flex', padding: 16, justifyContent: 'center'}}>
          <button
            className="text-xl font-semibold w-2/5 h-12 rounded-lg text-slate-50 bg-blue-500 shadow-md"
            onClick={() => handleFormSubmit(formAAnswers)}
          >
            확인
          </button>
        </div>
      </div> */}
    </>
  );
}
