interface HeaderProps {
  title: string;
  description?: string;
};

const Header = (headerProps: HeaderProps) => {
  return (
    <header className="min-w-full space-y-2">
      <p className="text-4xl font-bold">
        {headerProps.title}
      </p>
      <p className="text-xl font-medium">
        {headerProps.description}
      </p>
    </header>
  )
};

export default Header;
