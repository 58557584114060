import { Link, useLocation } from "react-router-dom";
import { ChallengeType } from ".";


export const Preview = () => {

  const location = useLocation();
  const challenge = location.state?.challenge as ChallengeType;

  return (
    <>
      <Link to="..">&lt; 돌아가기</Link>
      <div className="flex flex-col items-center space-y-6">
        {challenge ? (
          <>
            <h3 className="text-2xl">{challenge.name}</h3>
            <img src={challenge.img} alt={challenge.name} />
            <p className="text-lg text-center px-6">
              {challenge.description}
            </p>
            <br />
            <p className="text-lg text-center px-6">
              취침 전 계획 &gt; 아침 기상 &gt; 제한 시간 내에 미션 수행 &gt; 인증 &gt; 성공
            </p>
            <Link to={"../" + challenge.route} className="w-3/5 m-6 text-center">
              <button className="w-full h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg">
                선택
              </button>
            </Link>
          </>
        ) : (
          <>
            <p>Oops!</p>
          </>
        )}
      </div>
    </>
  );
};