import {QuestionFormProps} from "./QuestionFormProps";
import {useState} from "react";

export const LikertQuestion = (levels: number[], questionFormProps: QuestionFormProps) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const handleOptionSelect = (value: number) => {
    setSelectedOption(value);
    questionFormProps.onAnswerUpdate(value);
  };

  return (
    <div className="likert-question">
      <h3>
        <text className="font-bold">{questionFormProps.message}</text>
      </h3>
      <form>
        <div className="likert-scale p-2 pb-3.5">
          매우 아니다
          {levels.map((value) => (
            <label className="p-3" key={value}>
              <input
                type="radio"
                name="likertScale"
                value={value}
                checked={selectedOption === value}
                onChange={() => handleOptionSelect(value)}
              />
              {value}
            </label>
          ))}
          매우 그렇다
        </div>
      </form>
      {/*<p>Selected Option: {selectedOption}</p>*/}
    </div>
  )
    ;
}
