import { Link } from "react-router-dom"

export const NavPage = () => {

  return (
    <div className="min-h-screen flex flex-col items-center justify-center space-y-8">
      <h1>**DEV**</h1>
      <Link to={"../survey"} className="w-3/5 p-3 text-center rounded-lg border border-black">
        <button >
          설문조사  /survey
        </button>
      </Link>
      <Link to={"../challenge"} className="w-3/5 p-3 text-center rounded-lg border border-black">
        <button >
          챌린지  /challenge
        </button>
      </Link>
    </div>
  );
};
