import { Link, Outlet, useNavigate } from "react-router-dom";
import Header from "../../common/Header";

// export const MiracleMorning = () => {

//   return (
//     <div className="flex flex-col min-h-screen p-6 space-y-3">
//       <Header title={"챌린지"} description="미라클 모닝" />
//       <Outlet />
//     </div>
//   );
// };

// export const MiracleMorningIndex = () => {
//   return (
//     <div className="grow flex flex-col space-y-3">
//       <h2 className="min-w-full text-2xl text-start">
//         맞춤화 추천
//       </h2>
//       <div className="flex flex-col items-center p-6 border-2 rounded-2xl">
//         <h3 className="min-w-full text-lg text-start">
//           계획형
//         </h3>
//         <img 
//           className="m-12"
//           src="https://placekitten.com/g/200/200" 
//           width="200"
//           alt="test" 
//         />
//         <p className="text-center text-lg">
//           아침 기상 미션 계획
//         </p>
//         <Link to={"plan"} className="w-3/5 m-6 text-center">
//           <button className="w-full h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg">
//             미리보기
//           </button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// Default outline for challenge related pages
export const Challenge = () => {
  
  return (
    <div className="flex flex-col min-h-screen p-6 space-y-3">
      <Header title={"챌린지"} />
      <Outlet />
    </div>
  );
};

export interface ChallengeType {
  name: string;
  plan: boolean;
  img: string;
  description: string;
  route: string;
}

// Types of challenges
const challenges: ChallengeType[] = [
  {
    name: "아침 기상 미션 계획",
    plan: true,
    img: "https://placehold.co/200x200",
    description: "당신은 혼자 휴식을 취하며 알찬 시간을 보내는 걸 좋아하시네요. 아침 기상계획 뽑기 행동 포인트를 추천해 드릴게요!",
    route: "miraclemorning/preference",
  }, {
    name: "하고 싶지 않은 미션",
    plan: false,
    img: "https://placehold.co/200x100",
    description: "뒤로 돌아가세요",
    route: "foo",
  },
];

// Index page for challenges
export const ChallengeIndex = () => {

  const navigate = useNavigate();

  return (
    <div className="grow flex flex-col">
      <h2 className="min-w-full text-2xl text-start">
        맞춤화 추천
      </h2>
      {challenges.map((item) => (
        <div 
          key={item.name}
          className="flex flex-col items-center p-6 my-6 border-2 rounded-2xl"
        >
          <h3 className="min-w-full text-lg text-start">
            {item.plan ? "계획형": "즉흥형"}
          </h3>
          <img 
            className="m-12"
            src={item.img}
            width="200"
            alt="test" 
          />
          <p className="text-center text-lg">
            {item.name}
          </p>
          {/* <Link to={"preview"} className="w-3/5 m-6 text-center">
            <button className="w-full h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg">
              미리보기
            </button>
          </Link> */}
          <button
            className="w-3/5 m-6 text-center h-12 text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
            onClick={() => {
              navigate("preview", {
                state: { challenge: item as ChallengeType }
              });
            }}
          >
            미리보기
          </button>
        </div>
      ))}
    </div>
  );
};
