import {useNavigate, useParams} from "react-router-dom";
import {datasets, myDataset} from "../datasets";
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import Header from "../../../common/Header";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const RoleModelElement = () => {
  const navigate = useNavigate()
  const params = useParams()

  const roleModelId = params.roleModelId as unknown as number

  const dataset = datasets.find(
    (value) => value.id == roleModelId
  )!

  const data = {
    labels: dataset.labels,
    datasets: [
      {
        label: dataset.name,
        data: dataset.values,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      }, {
        label: myDataset.name,
        data: myDataset.values,
        // backgroundColor: 'rgba(255, 99, 132, 0.2)',
        // borderColor: 'rgba(255, 99, 132, 1)',
        // borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Header title={`롤모델: ${dataset.name}`}/>
      <text className="whitespace-pre-line">
        {dataset.description}
      </text>
      <div
        className="content-center flex justify-center items-center h-96 w-full"
      >
        <Bar id={`RoleModelElement-${roleModelId}`} data={data}/>
        {/*<Line id={`RoleModelElement-${roleModelId}`} data={data}/>*/}
      </div>
      <div
        className="content-center flex justify-center items-center h-20 w-full"
      >
        <button
          className="text-xl font-semibold w-2/5 h-12 rounded-lg text-slate-50 bg-blue-500 shadow-md"
          onClick={() => {
            navigate(-1)
          }}>
          뒤로가기
        </button>
      </div>
    </div>
  )
}
