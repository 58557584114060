export class Dataset {
  id: number;
  name: string;
  description: string;
  labels: string[];
  values: number[];

  constructor(id: number, name: string, description: string, labels: string[], values: number[]) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.labels = labels;
    this.values = values;
  }
}
