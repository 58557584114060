import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import request from '../../../common/axiosInstance';

interface Mission {
  missionId: number,
  title: string,
}

export const Mission = () => {

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // States
  const [mission, setMission] = useState<Mission>({ missionId: 0, title: "" });
  const [retryCount, setRetryCount] = useState<number>(5);

  // Effects
  useEffect(() => {
    request.put("/dailyChallengeMissions", {
      dailyChallengeId: location.state.dailyChallengeId,
      missionOrder: location.state.missionOrder
    })
      .then((res) => setRetryCount(res.data.drawNum))
      .then(() => {
        request.get(`/dailyChallenges/${location.state.dailyChallengeId}/dailyChallengeMissions`)
          .then((res) => res.data[location.state.missionOrder - 1])
          .then((data) => data.missionId)
          .then((missionId) => {
            if (missionId !== 0)
              request.get(`/missions/${missionId}`)
                .then((res) => {
                  setMission({ missionId: missionId, title: res.data.title });
                });
          });
      });
  }, [retryCount]);

  return (
    <>
      <div className="flex flex-col">
        <img 
          src="https://placehold.co/300x300" 
          alt="Mission Image" 
        />
        <div className="text-center my-6 space-y-6">
          <h3 className="text-2xl">{mission.title}</h3>
          <p className="text-lg">Description: TBA</p>
        </div>
        <div className="my-6 space-y-3">
          <p>Traits: TBA</p>
          <p>Description: TBA</p>
        </div>
        <div className="w-full flex justify-around">
          <button 
            className="w-2/5 h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
            disabled={!(retryCount > 0)}
            onClick={() => {
              // API: Update retry count
              request.patch(`/dailyChallengeMissions/reRoll?dailyChallengeId=${location.state.dailyChallengeId}&missionOrder=${location.state.missionOrder}`)
                .then((res) => setRetryCount(res.data.drawNum));
            }}
          >
            다시 뽑기 {retryCount}회
          </button>
          <button 
            className="w-2/5 h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
            onClick={() => {
              navigate(-1);
            }}
          >
            선택
          </button>
        </div>
      </div>
    </>
  )
}