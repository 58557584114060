import image from './assets/temp_logo.png';

function App() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center space-y-8">
      <img src={image} alt="Logo" width="60%" />
      <div className="text-xl font-medium space-y-1">
        <p>당신의 독특한 성격을 반영하여,</p>
        <p>매일을 새롭고 의미있게 만드는</p>
        <p>개인화된 챌린지를 경험하세요.</p>
      </div>
      <button 
        className="text-xl font-semibold w-3/5 h-12 rounded-lg text-slate-50 bg-blue-500 shadow-md"
        onClick={() => {window.location.href = "/nav"}}
      >
        시작하기
      </button>
    </div>
  );
}

export default App;
