import {Dataset} from "./Dataset";

export const myDataset = new Dataset(
  0,
  "나",
  "",
  ['경험 개방성', '성실성', '외향성', '우호성', '신경증성'],
  [64, 53, 27, 60, 36]
);

export const datasets = [
  new Dataset(
    1,
    "생각하는 과학자", // 아인슈타인
    `
- 학교 성적이 뛰어나지 않았지만, 수학과 과학에 대한 깊은 흥미와 호기심을 발전시켰습니다.
- 대학에서 이론물리학을 공부하면서 독특한 사고방식을 구축, 나중에 이를 바탕으로 혁신적인 이론을 발표하여 과학계에 큰 변화를 가져왔습니다.
- 그의 이론은 세계를 바라보는 새로운 방식을 제시하며 역사상 가장 영향력 있는 물리학자 중 한 명으로 자리매김했습니다.`,
    ['경험 개방성', '성실성', '외향성', '우호성', '신경증성'],
    [90, 70, 45, 60, 55]
  ),
  new Dataset(
    2,
    "깨달음을 지향하는 종교지도자", // 석가모니
    `
- 깨달음을 얻기 위한 고행과 명상에 집중하여 불교의 가르침을 설파했습니다.
- 풍요로운 삶을 버리고, 인간의 고통을 해결하기 위해 출가하였습니다.
- 그는 수많은 고행과 명상을 통해 깨달음을 얻고, 중도의 길을 제시하였습니다.
    `,
    ['경험 개방성', '성실성', '외향성', '우호성', '신경증성'],
    [79, 76, 38, 78, 26],
  ),
  new Dataset(
    3,
    "질문하는 철학자", // 소크라테스
    `
- 타인과의 대화를 통해 그들의 사고를 자극하고, 철학적 질문을 통해 진리를 탐구하였습니다.
- 평범한 장인 가정에서 태어났지만, 젊은 시절부터 철학에 깊은 관심을 가졌습니다.
- 다양한 사람들과 대화하며 그들의 신념과 지식을 도전하였습니다.
- 이러한 대화와 질문을 통해 그는 많은 사람들에게 깨달음을 주었고, 자신의 철학적 방법론을 개발하게 되었습니다.
    `,
    ['경험 개방성', '성실성', '외향성', '우호성', '신경증성'],
    [84, 74, 68, 68, 24],
  ),
]
