import {Dataset} from "./Dataset";
import {useNavigate} from "react-router-dom";
import {Radar} from "react-chartjs-2";

export const RoleModelCard = ({dataset}: {
  dataset: Dataset
}) => {
  const navigate = useNavigate();

  const {id, name, description, labels, values} = dataset;
  const data = {
    labels: labels,
    datasets: [
      {
        label: '제점수는요',
        data: values,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div onClick={() => {
      navigate(`/rolemodel/${id}`)
    }}>
      <h1>{name}</h1>
      <div>
        <Radar id={`RoleModelCard-${id}`} data={data}/>
      </div>
      <text className="whitespace-pre-line">
        {dataset.description}
      </text>
    </div>
  );
}
