import {useState} from 'react';
import Header from '../../common/Header';
import request from '../../common/axiosInstance';
import {useLocation, useNavigate} from 'react-router-dom';

const Login = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // States
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');

  const handleLogin = async () => {
    await request.post('/auth/login', {
      username: username,
      password: password,
    })
      .then((res) => {
        const {accessToken} = res.data;
        if (!accessToken) {
          throw new Error('No access token');
        }

        window.sessionStorage.setItem('token', accessToken);
        setStatus('LOGIN SUCCESS');
      })
      .then(() => {
        navigate(location.state?.path, {
          replace: true,
        });
      })
      .catch((err) => {
        setStatus(`LOGIN FAILED: ${err}`)
      })
  };

  const handleSignup = async () => {
    await request.post('/auth/signup', {
      username: username,
      password: password,
    })
      .then((res) => {
        const {accessToken} = res.data;
        window.sessionStorage.setItem('token', accessToken);
        setStatus('SIGNUP SUCCESS');
      })
      .catch((err) => {
        setStatus(`SIGNUP FAILED: ${err}`)
      })
  }

  return (
    <div className="min-h-screen flex flex-col p-4">
      <Header title={'로그인'}/>
      <div className="grow flex flex-col justify-start items-center pt-20 space-y-3">
        <input
          className="min-w-full h-10 text-sm px-3 border-2 rounded-lg outline-blue-500"
          type='text'
          value={username}
          placeholder='Username'
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="min-w-full h-10 text-sm px-3 border-2 rounded-lg outline-blue-500"
          type='password'
          value={password}
          placeholder='Password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="min-w-full h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
          onClick={() => handleLogin()}
        >
          로그인
        </button>
        <button
          className="min-w-full h-12 text-base"
          onClick={() => handleSignup()}
        >
          회원가입
        </button>
        <p>{status}</p>
      </div>
    </div>
  )
};

export default Login;
