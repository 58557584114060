import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PreferenceForm } from './PreferenceForm';
import request from '../../../common/axiosInstance';

const prefixes = ['첫', '두', '세'];

const numMissions = {
  "쉬움": 1,
  "보통": 2,
  "어려움": 3,
};

export const SetMissions = () => {
  // Hooks
  const navigate = useNavigate();

  // States
  const [preferences, setPreferences] = useState<PreferenceForm>();
  const [dailyChallengeId, setDailyChallengeId] = useState<number>();
  const [missions, setMissions] = useState<any[]>([...Array(3)]);
  const [missionTitles, setMissionTitles] = useState<string[]>([...Array(3)]);

  // Handlers
  const handlePickMission = (missionOrder: number) => {
    request.put("/dailyChallengeMissions", {
      "dailyChallengeId": dailyChallengeId,
      "missionOrder": missionOrder,
    })
      .then(() => {
        // Navigate to mission page
        navigate("../mission", {
          state: {
            dailyChallengeId: dailyChallengeId,
            missionOrder: missionOrder,
          }
        });
      })
  }
  
  useEffect(() => {
    request.get("/challengeUsers/latest")
      .then((res) => res.data)
      .then((data) => {
        setPreferences({
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          difficulty: (data.difficulty === "HARD") ? (
            "어려움"
          ) : (
            (data.difficulty === "NORMAL") ? (
              "보통"
            ) : (
              "쉬움"
            )
          ),
        });
        return data.challengeUserId;
      })
      .then((challengeUserId) => {
        // Daily Challenge: Upsert daily challenge
        request.put("/dailyChallenges", {
          "challengeUserId": challengeUserId,
          "date": "2024-05-19",
        })
          .then((res) => {
            setDailyChallengeId(res.data.dailyChallengeId);
            return res.data.dailyChallengeId;
          })
          .then((dailyChallengeId) => {
            // Daily Challenge: Get all missions
            request.get(`/dailyChallenges/${dailyChallengeId}/dailyChallengeMissions`)
              .then((res) => res.data)
              .then((data) => {
                let newMissions = [...missions];
                data.map((item: any, idx: number) => {
                  newMissions[idx] = item.missionId;
                });
                setMissions(newMissions);
              });
          });
      })
      .then(() => {
        // Get all mission names
        let newMissionTitles = [...missionTitles];
        missions.map((item: any, idx: number) => {
          if (item)
            request.get(`/missions/${item}`)
              .then((res) => newMissionTitles[idx] = res.data.title);
        });

        setMissionTitles(newMissionTitles);
      })
      .catch((err) => console.log(err));
  }, [missions[0]]);

  return (
    <div className="grow flex flex-col">
      <p onClick={() => navigate(-1)}>뒤로가기</p>
      <h3 className="text-2xl">미션 날짜: {preferences?.startDate.toLocaleDateString()}</h3>
      {/* <h3 className="text-2xl my-3">기상 시간: {data?.startTime}</h3> */}
      {preferences ? (
        [...Array(numMissions[preferences.difficulty])].map((_, idx) => (
          <div key={idx}>
            <hr />
            <div className="flex justify-between mt-3">
              <p className="text-lg">{prefixes[idx]}번째 미션</p>
              <p className="text-lg">시작 시간</p>
              <input 
                type="time" 
                className="text-lg"
                // disabled={!data?.missions[idx]?.startTime}
                // value={startTimes[idx]}
                // onChange={(e) => {
                //   // TODO: Add constraints
                //   data.missions[idx].startTime = e.target.value;
                // }}
              />
            </div>
            <div className="flex items-center my-3">
              <div className="w-3/4 flex flex-col items-center">
                <p>{missionTitles[idx]}</p>
              </div>
              <button 
                className="w-1/4 h-12 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
                onClick={() => {
                  handlePickMission(idx + 1);
                }}
              >
                {/* {data?.missions[idx] ? "재선택" : "선택"} */}
                선택
              </button>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
      <button
        className="w-full h-12 mt-10 text-base text-slate-50 font-semibold bg-blue-500 border-2 rounded-lg"
      >
        계획 완료
      </button>
    </div>
  );
};
