import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import {Survey} from "./pages/survey";
import {Challenge, ChallengeIndex} from './pages/challenge';
import Login from './pages/login';
import {AuthProvider} from './common/AuthProvider';
import {NavPage} from './pages/NavPage';
import {Preview} from './pages/challenge/Preview';
import {Preference} from './pages/challenge/miraclemorning/Preference';
import {SetMissions} from './pages/challenge/miraclemorning/SetMissions';
import {Mission} from './pages/challenge/miraclemorning/Mission';
import {RoleModel} from './pages/rolemodel';
import {RoleModelElement} from './pages/rolemodel/element';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  }, {
    path: "/login",
    element: <Login/>,
  }, {
    path: "/nav",
    element: <AuthProvider component={<NavPage/>}/>,
  }, {
    path: "/survey",
    element: <AuthProvider component={<Survey disableApi={false}/>}/>,
  }, {
    path: "/challenge",
    element: <AuthProvider component={<Challenge/>}/>,
    children: [
      {
        index: true,
        element: <ChallengeIndex/>
      }, {
        path: "preview",
        element: <Preview/>
      }, {
        path: "miraclemorning",
        element: <Outlet/>,
        children: [
          {
            path: "preference",
            element: <Preference/>,
          }, {
            path: "set-mission",
            element: <SetMissions/>
          }, {
            path: "mission",
            element: <Mission/>
          }
        ]
      }
      // }, {
      //   path: "plan",
      //   element: <PlanningType />,
      //   children: [
      //     {
      //       index: true,
      //       element: <PlanningTypeIndex />,
      //     }, {
      //       path: "period",
      //       element: <PlanningPeriod />,
      //     }, {
      //       path: "mission",
      //       element: <PlanningMission />,
      //     }, {
      //       path: "mission/:missionId",
      //       element: <Mission />
      //     },
      //   ],
    ],
  }, {
    path: "/rolemodel",
    children: [
      {
        index: true,
        element: <RoleModel/>
      },
      {
        path: "survey",
        element: <Survey disableApi={true}/>
      },
      {
        path: ":roleModelId",
        element: <RoleModelElement/>
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RouterProvider router={router}/>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
